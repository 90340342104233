$(document).ready(function() {
    $('form').submit(function(e) 
    {
        $(this).find('[data-loading-text]').button('loading');
    });
    
    // $('.form-control-rte').wysihtml5();

    $('[data-confirm-text]').click(function(e) {
        var text = $(this).data('confirmText');
        if ( ! confirm(text)) {
            return false;
        }
    });

    $('[data-toggle="tooltip"]').tooltip();

    if ($('.epiceditor').length) {
        $('.epiceditor').each(function() {
            var Parent = this;
            var editor = new EpicEditor({
                container: Parent,
                basePath: '/build/css/epiceditor',
                theme: {
                    base: '/themes/base/epiceditor.css',
                    preview: '/themes/preview/github.css',
                    editor: '/themes/editor/epic-light.css'
                },
                autogrow: {
                    minHeight: 300
                },
                clientSideStorage: false
            }).load();
        })
    }
});